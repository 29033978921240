// src/pages/DashboardHome.js

import React from 'react';

function DashboardHome() {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Welcome Back!</h1>
      {/* Add dashboard content here */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="text-xl font-semibold mb-2">Total Documents</div>
          <div className="text-4xl font-bold">15</div>
        </div>
        {/* Repeat for other cards */}
      </div>
    </div>
  );
}

export default DashboardHome;





