// src/pages/DocumentEditor.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function DocumentEditor() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [stampAmount, setStampAmount] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handlePdfUpload = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Implement the save logic here, e.g., upload to Firebase
      // For demonstration purposes, we'll log the data
      console.log('Title:', title);
      console.log('Content:', content);
      console.log('PDF File:', pdfFile);
      console.log('Stamp Amount:', stampAmount);
      console.log('Recipient Email:', recipientEmail);
      console.log('Current User:', currentUser);

      // Simulate a delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // After saving, navigate to the documents list
      navigate('/dashboard/documents');
    } catch (error) {
      console.error('Error saving document:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">
        Create New Document
      </h1>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">
          Document Title
        </label>
        <input
          type="text"
          className="w-full border border-gray-300 p-2 rounded-md"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">
          Upload PDF
        </label>
        <input
          type="file"
          accept="application/pdf"
          onChange={handlePdfUpload}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">
          Or Create Document Content
        </label>
        <textarea
          className="w-full border border-gray-300 p-2 rounded-md h-40"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">
          Stamp Paper Amount
        </label>
        <select
          className="w-full border border-gray-300 p-2 rounded-md"
          value={stampAmount}
          onChange={(e) => setStampAmount(e.target.value)}
        >
          <option value="">Select Amount</option>
          <option value="50">₹50</option>
          <option value="100">₹100</option>
          <option value="500">₹500</option>
        </select>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 font-medium mb-2">
          Recipient Email
        </label>
        <input
          type="email"
          className="w-full border border-gray-300 p-2 rounded-md"
          value={recipientEmail}
          onChange={(e) => setRecipientEmail(e.target.value)}
        />
      </div>
      <button
        onClick={handleSave}
        className="bg-blue-600 text-white px-6 py-3 rounded-md"
        disabled={loading}
      >
        {loading ? 'Saving...' : 'Save and Send'}
      </button>
    </div>
  );
}

export default DocumentEditor;




