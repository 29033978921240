// src/pages/Settings.js

import React from 'react';
import { Typography, Container } from '@mui/material';

function Settings() {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      <Typography variant="body1">
        Settings page content goes here.
      </Typography>
    </Container>
  );
}

export default Settings;


