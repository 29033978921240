// src/pages/SignIn.js

import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Button, Typography, Container, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const { loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await loginWithGoogle();
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to sign in with Google');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sign In
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" onClick={handleGoogleSignIn}>
          Sign In with Google
        </Button>
      </Box>
    </Container>
  );
}

export default SignIn;


