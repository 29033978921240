// src/pages/Profile.js

import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { updateProfile } from 'firebase/auth';

function Profile() {
  const { currentUser } = useAuth();
  const [displayName, setDisplayName] = useState(currentUser.displayName || '');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleUpdateProfile = async e => {
    e.preventDefault();
    try {
      await updateProfile(currentUser, { displayName });
      setMessage('Profile updated successfully.');
      setError('');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile.');
      setMessage('');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      {message && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <form onSubmit={handleUpdateProfile}>
        <TextField
          label="Display Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Save Changes
        </Button>
      </form>
    </Container>
  );
}

export default Profile;



