// src/pages/DocumentView.js

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Typography, Container, Box, Button } from '@mui/material';

function DocumentView() {
  const { id } = useParams();
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const fetchDocument = async () => {
      const docRef = doc(firestore, 'documents', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocument({ id: docSnap.id, ...docSnap.data() });
      }
    };
    fetchDocument();
  }, [id]);

  if (!document) {
    return (
      <Container>
        <Typography variant="h6">Loading document...</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {document.title}
      </Typography>
      <Box
        dangerouslySetInnerHTML={{ __html: document.content }}
        sx={{ border: '1px solid #ccc', p: 2, borderRadius: 1 }}
      ></Box>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/dashboard/documents/${id}/edit`}
        sx={{ mt: 2 }}
      >
        Edit Document
      </Button>
    </Container>
  );
}

export default DocumentView;



