// src/pages/HomePage.js

import React from 'react';
import { useAuth } from '../context/AuthContext';

function HomePage() {
  const { currentUser, loginWithGoogle } = useAuth();

  const handleSignIn = async () => {
    try {
      await loginWithGoogle();
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Sign in failed:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white shadow">
        <nav className="container mx-auto flex items-center justify-between py-4">
          <div className="text-2xl font-bold text-gray-800">DigiContract</div>
          <div>
            {currentUser ? (
              <button
                onClick={() => (window.location.href = '/dashboard')}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                Dashboard
              </button>
            ) : (
              <>
                <button
                  onClick={handleSignIn}
                  className="text-gray-800 mr-4"
                >
                  Sign In
                </button>
                <button
                  onClick={handleSignIn}
                  className="bg-blue-600 text-white px-4 py-2 rounded-md"
                >
                  Get Started
                </button>
              </>
            )}
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section
        className="flex-grow bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/hero-background.jpg')" }}
      >
        <div className="bg-black bg-opacity-50">
          <div className="container mx-auto text-center py-24">
            <h1 className="text-5xl font-bold text-white mb-6">
              Simplify Your Contract Management
            </h1>
            <p className="text-xl text-gray-200 mb-8">
              Create, sign, and manage contracts digitally with ease and security.
            </p>
            <button
              onClick={handleSignIn}
              className="bg-blue-600 text-white px-8 py-4 rounded-md text-lg"
            >
              Get Started for Free
            </button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="container mx-auto py-16">
        <h2 className="text-4xl font-bold text-center mb-12">
          Why Choose DigiContract?
        </h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-8 bg-white shadow-lg rounded-md text-center">
              <img
                src="/assets/secure.svg"
                alt="Secure"
                className="w-20 h-20 mx-auto mb-6"
              />
              <h3 className="text-2xl font-semibold mb-4">
                Secure Document Management
              </h3>
              <p className="text-gray-600">
                Manage your contracts securely with our encrypted platform.
              </p>
            </div>
          </div>
          {/* Repeat for other features */}
        </div>
      </section>

      {/* How It Works Section */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            How It Works
          </h2>
          <div className="flex flex-wrap -mx-4">
            {/* Step 1 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="p-8 bg-white shadow-lg rounded-md text-center">
                <div className="text-blue-600 text-5xl mb-4">1</div>
                <h3 className="text-2xl font-semibold mb-4">
                  Create or Upload
                </h3>
                <p className="text-gray-600">
                  Use our templates or upload your own document.
                </p>
              </div>
            </div>
            {/* Repeat for other steps */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <p>&copy; {new Date().getFullYear()} DigiContract. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
