// src/components/Sidebar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  HomeIcon,
  DocumentIcon,
  UserIcon,
  CogIcon,
} from '@heroicons/react/outline';

// src/components/Sidebar.js

function Sidebar() {
  const navItems = [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: <HomeIcon className="h-6 w-6" />,
    },
    {
      name: 'Documents',
      path: '/dashboard/documents',
      icon: <DocumentIcon className="h-6 w-6" />,
    },
    {
      name: 'Profile',
      path: '/dashboard/profile',
      icon: <UserIcon className="h-6 w-6" />,
    },
    {
      name: 'Settings',
      path: '/dashboard/settings',
      icon: <CogIcon className="h-6 w-6" />,
    },
  ];

  return (
    <div className="w-64 bg-white shadow-md">
      <div className="p-6 text-2xl font-bold">DigiContract</div>
      <nav className="mt-6">
        {navItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            className="flex items-center py-2.5 px-4 rounded transition duration-200 hover:bg-blue-100"
            activeClassName="bg-blue-200"
          >
            <span className="mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar;

