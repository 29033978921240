// src/pages/Documents.js

import React, { useEffect, useState } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from '@mui/material';
import { Link } from 'react-router-dom';

function Documents() {
  const { currentUser } = useAuth();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const q = query(
        collection(firestore, 'documents'),
        where('userId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDocuments(docs);
    };
    fetchDocuments();
  }, [currentUser]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Your Documents
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/dashboard/documents/new"
        sx={{ mb: 2 }}
      >
        Create New Document
      </Button>
      <List>
        {documents.map(doc => (
          <ListItem
            key={doc.id}
            button
            component={Link}
            to={`/dashboard/documents/${doc.id}`}
          >
            <ListItemText
              primary={doc.title}
              secondary={new Date(doc.updatedAt?.seconds * 1000).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default Documents;


